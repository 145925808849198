import React from 'react'
import { Link } from 'gatsby'
import { css } from 'styled-components'

import Layout from '../components/layout'
import SEO from '../components/seo'
import FooterIndex from '../components/footerIndex'
import { device } from '../styles/devices'
import RuthImage from '../components/ruthImage'
import FranziImage from '../components/franziImage'
import HeaderIndex from '../components/headerIndex'

const TeamPage = () => (
  <Layout>
    {/* <Layout isIndexPage> */}
    <SEO title="Team" />
    {/* <HeaderIndex contentRender={() => <>Wir stellen uns vor!</>} /> */}

    <div className="grid">
      <div className="grid-contained">
        {/* Team */}
        <section
          css={css`
            margin-top: 100px;
            margin-bottom: 100px;
          `}
        >
          <div
            css={css`
              @media ${device.tablet} {
                display: flex;
                justify-content: space-around;
              }
            `}
          >
            {/* Ruth */}
            <div
              css={css`
                flex-grow: 1;
                text-align: center;
              `}
            >
              <div>
                <RuthImage />
              </div>
              <h2
                css={css`
                  margin-top: 18px;
                  font-size: 2.8rem;
                `}
              >
                Ruth Nießen
              </h2>
              <ul
                css={css`
                  margin-top: 8px;
                  font-size: 1em;
                  line-height: 1.75em;
                `}
              >
                <li>Mediatorin</li>
                <li>Sozialarbeiterin</li>
                <li>Nonprofit-Expertin</li>
                <li>Organisationsentwicklerin</li>
                <li>Changemanagerin</li>
                <li>
                  <Link
                    to="/team/ruth"
                    css={css`
                      display: block;
                      margin-top: 20px;
                      color: #3668ed;
                    `}
                  >
                    Mehr über Ruth
                  </Link>
                </li>
              </ul>
            </div>

            {/* Franzi */}
            <div
              css={css`
                flex-grow: 1;
                text-align: center;
                margin-top: 100px;

                @media ${device.tablet} {
                  margin-top: 0;
                }
              `}
            >
              <div>
                <FranziImage />
              </div>
              <h2
                css={css`
                  margin-top: 18px;
                  font-size: 2.8rem;
                `}
              >
                Franziska Schacht
              </h2>
              <ul
                css={css`
                  margin-top: 8px;
                  font-size: 1em;
                  line-height: 1.75em;
                `}
              >
                <li>Mediatorin</li>
                <li>Linguistin</li>
                <li>Kommunikationswissenschaftlerin</li>
                <li>IT-Beraterin</li>
                <li>Prozessexpertin</li>
                <li>
                  <Link
                    to="/team/franziska"
                    css={css`
                      display: block;
                      margin-top: 20px;
                      color: #3668ed;
                    `}
                  >
                    Mehr über Franziska
                  </Link>
                </li>
              </ul>
            </div>
          </div>
        </section>
      </div>
    </div>

    <div className="grid">
      <div
        className="grid-contained"
        css={css`
          & > p {
            margin-bottom: 20px;
            line-height: 33.18px;
            letter-spacing: -0.004em;
          }
        `}
      >
        <p>
          Wir sind Ruth und Franziska, zwei junge Mediatorinnen, aus
          Berlin.
        </p>
        <p>
          Jede für sich blickt auf langjährige berufliche Erfahrungen zurück.
        </p>
        <p>
          Während Franziska Erfahrungen in verschiedenen IT-Projekten, agilen
          Arbeitsmethoden und Marketing sammelte, Kunden beriet und
          teamübergreifende Veranstaltungen und Projekte organisierte, arbeitete
          Ruth als Senior Projektmanagerin in der politischen
          Erwachsenenbildung, als Veranstaltungsmanagerin und Verwaltungsleitung
          sowie im Ausland als Freiwillige.
        </p>
        <p>Beide erlebten Teamwork als Schlüssel zum Erfolg.</p>
        <p>Also packten wir es an:</p>
        <p>
          Konfliktrezept ist unser Ansatz, in dem wir unsere erlernten Methoden
          und Kompetenzen sowie unsere jeweiligen Erfahrungen und unser Wissen
          für Sie nutzbar machen!{' '}
        </p>
        <p>
          Unser Ziel ist es, Aha-Effekte zu erzeugen und so die persönliche
          Weiterentwicklung und damit die Zusammenarbeit zu fördern.{' '}
        </p>
        <p>
          Im Zeitalter der Digitalisierung und den damit einhergehenden
          Herausforderungen ist es wichtiger denn je, die vorhandenen Ressourcen
          effizient einzusetzen und zu stärken.{' '}
        </p>
        <p>
          Authentisch und ehrlich bieten wir für jede Organisation, jedes Team
          sowie jeden Einzelnen individuelle Formate an.{' '}
        </p>
        <p>
          Sie sparen Zeit, Geld und wertvolle Energie, wenn Sie unseren Ansatz
          Konfliktrezept nutzen.{' '}
        </p>
        <p>
          Unser Methodenkoffer ist bunt und facettenreich. Wir stellen mit Ihnen
          ein individuell passendes Angebot zusammen.
        </p>
        <div
          css={css`
            margin: 4rem 0;
            line-height: 33.18px;

            & h3 {
              font-size: 2.8rem;
            }

            & > ul {
              margin-left: 1.6rem;
              list-style: disc;
            }
          `}
        >
          <h3>Auf einem Blick</h3>
          <ul>
            <li>Jung & dynamisch</li>
            <li>Offen & neugierig</li>
            <li>Interkulturell & projektbasiert</li>
            <li>Kreativ & modern</li>
            <li>Individuell & persönlich</li>
          </ul>
        </div>
        <p>
          <span>Lust mit uns Zusammenzuarbeiten? </span>
          <Link
            css={css`
              color: #3668ed;
              border-bottom: 2px solid #3668ed;
              /* text-decoration: underline; */
            `}
            to="/kontakt"
          >
            Kontaktieren Sie uns
          </Link>
          <span> jetzt für ein kostenfreies unverbindliches Vorgespräch.</span>
        </p>
      </div>
    </div>

    <FooterIndex />
  </Layout>
)

export default TeamPage
