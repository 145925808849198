import React from 'react'
import { useStaticQuery, graphql } from 'gatsby'
import Img from 'gatsby-image'

const RuthImage = () => {
  const data = useStaticQuery(graphql`
    query {
      placeholderImage: file(relativePath: { eq: "franzi.png" }) {
        childImageSharp {
          fixed(
            width: 200
            height: 200
            traceSVG: { background: "#fff", color: "#0536b9" }
          ) {
            ...GatsbyImageSharpFixed_tracedSVG
          }
        }
      }
    }
  `)

  return (
    <Img
      fixed={data.placeholderImage.childImageSharp.fixed}
      style={{ borderRadius: '50%' }}
    />
  )
}

export default RuthImage
