import React from 'react'
import { css } from 'styled-components'
import { device } from '../styles/devices'

const HeaderIndex = ({ contentRender, heightOffset = 0 }) => (
  <div
    css={css`
      margin-bottom: 100px;
      padding-bottom: 50px;
      /* background: linear-gradient(170deg, #3668ed 38%, #6d95ff 92%); */
      background: #3668ed;

      @media ${device.tablet} {
        padding-bottom: 75px;
      }
    `}
  >
    <div
      className="grid"
      css={css`
        position: relative;
        padding-top: 100px;
        opacity: 0.95;
        color: #fff;
        font-size: 2rem;
        font-weight: 400;
        line-height: 1.75em;

        @media ${device.tablet} {
          padding-top: 125px;
        }
      `}
    >
      <div className="grid-contained">
        <div
          css={css`
            @media ${device.tablet} {
              margin: 0 auto;
              /* width: 700 px; */
            }

            @media ${device.laptop} {
              margin: 0 auto;
              /* width: 950px; */
            }
          `}
        >
          {contentRender()}
        </div>
      </div>
    </div>
  </div>
)

// const HeaderIndex = ({ contentRender, heightOffset = 0 }) => (
//   <div
//     css={css`
//       position: relative;
//       margin-bottom: 75px;
//       z-index: 1;
//       height: 650px;

//       @media ${device.tablet} {
//         height: 375px;
//       }

//       @media ${device.laptop} {
//         height: 355px;
//       }

//       @media ${device.desktop} {
//         height: 375px;
//       }
//     `}
//   >
//     <div
//       css={css`
//         position: absolute;
//         margin-top: ${heightOffset}px;
//         width: 100%;
//         height: 650px;
//         overflow: hidden;
//         transform-origin: 0;
//         transform: skewY(-4deg);
//         background: linear-gradient(170deg, #3668ed 17%, #6d95ff 92%);
//         /* border-bottom-left-radius: 28%;
//         border-bottom-right-radius: 57%; */

//         & > span {
//           position: absolute;
//           width: 0;
//           height: 0;
//         }

//         @media ${device.tablet} {
//           height: 375px;
//         }

//         @media ${device.laptop} {
//           height: 355px;
//         }

//         @media ${device.desktop} {
//           height: 375px;
//         }
//       `}
//     >
//       <span
//         css={css`
//           top: -115px;
//           left: 0;
//           border-top: 190px solid transparent;
//           border-bottom: 144px solid transparent;
//           border-left: 265px solid #3668ed8a;

//           @media ${device.tablet} {
//             top: -253px;
//             border-top: 337px solid transparent;
//             border-bottom: 152px solid transparent;
//             border-left: 306px solid #3668ed8a;
//           }

//           @media ${device.laptop} {
//             top: -244px;
//             border-top: 337px solid transparent;
//             border-bottom: 207px solid transparent;
//             border-left: 503px solid #3668ed8a;
//           }
//         `}
//       />
//       <span
//         css={css`
//           top: 300px;
//           right: 0;
//           border-top: 400px solid transparent;
//           border-bottom: 400px solid transparent;
//           border-right: 750px solid #6d95ff;

//           @media ${device.tablet} {
//             top: 22vh;
//           }
//         `}
//       />
//     </div>
//     <div
//       className="grid"
//       css={css`
//         position: relative;
//         padding-top: 100px;
//         opacity: 0.8;
//         color: #fff;
//         font-size: 2rem;
//         font-weight: 400;
//         line-height: 1.75em;

//         @media ${device.tablet} {
//           padding-top: 125px;
//         }
//       `}
//     >
//       <div className="grid-contained">
//         <p
//           css={css`
//             @media ${device.tablet} {
//               margin: 0 auto;
//               width: 700px;
//             }

//             @media ${device.laptop} {
//               margin: 0 auto;
//               width: 900px;
//             }
//           `}
//         >
//           {contentRender()}
//         </p>
//       </div>
//     </div>
//   </div>
// )

export default HeaderIndex
